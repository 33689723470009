const SCROLL_ANIMATION_TRIGGER_CLASSNAME = 'scroll-trigger';
const SCROLL_ANIMATION_OFFSCREEN_CLASSNAME = 'scroll-trigger--offscreen';
const SCROLL_ANIMATION_CANCEL_CLASSNAME = 'scroll-trigger--cancel';

const SVG_TRIGGER_CLASSNAME = 'animated-svg';
const SVG_ANIMATED_ELEMENT_CLASSNAME = 'animated-path';
const SVG_ANIMATION_PLAY_CLASSNAME = 'svg-play-animation';

// Scroll in animation logic
function onIntersection(elements, observer) {
  elements.forEach((element, index) => {
    if (element.isIntersecting) {
      const elementTarget = element.target;
      if (elementTarget.classList.contains(SCROLL_ANIMATION_OFFSCREEN_CLASSNAME)) {
        elementTarget.classList.remove(SCROLL_ANIMATION_OFFSCREEN_CLASSNAME);
        elementTarget.dispatchEvent(new Event('on:sectionAnimation'));
      }
      observer.unobserve(elementTarget);
    } else {
      element.target.classList.add(SCROLL_ANIMATION_OFFSCREEN_CLASSNAME);
      element.target.classList.remove(SCROLL_ANIMATION_CANCEL_CLASSNAME);
    }
  });
}

function initializeScrollAnimationTrigger(rootEl = document) {
  const animationTriggerElements = Array.from(rootEl.getElementsByClassName(SCROLL_ANIMATION_TRIGGER_CLASSNAME));
  if (animationTriggerElements.length === 0) return;

  const observer = new IntersectionObserver(onIntersection, {
    rootMargin: '0px 0px -50px 0px',
  });
  animationTriggerElements.forEach((element) => observer.observe(element));
}

function animateSVG(rootEl = document) {
  const svgElements = Array.from(rootEl.getElementsByClassName(SVG_TRIGGER_CLASSNAME));
  if (svgElements.length === 0) return;

  svgElements.forEach((svgElement) => {
    const animPath = svgElement.querySelector('.'+SVG_ANIMATED_ELEMENT_CLASSNAME);
    
    if(!animPath) return;
    svgElement.addEventListener('mouseenter', () => {
      svgElement.classList.add(SVG_ANIMATION_PLAY_CLASSNAME);
    });
    animPath.addEventListener('animationend', () => {
      svgElement.classList.remove(SVG_ANIMATION_PLAY_CLASSNAME);
    })
  })

}

window.addEventListener('DOMContentLoaded', () => {
  initializeScrollAnimationTrigger();
});

document.addEventListener('DOMContentLoaded', () => {
  animateSVG();
});
